.parking-ticket-wrapper {
    font-family: 'SF-Pro-Text-Regular', sans-serif;
    src: local('SF-Pro-Text-Regular'), url("../../../assets/fonts/SF-Pro-Text-Regular.otf") format('otf');
    display: flex;
    flex-direction: column;
    width: 95%;
    height: auto;
    max-width: 770px;
    align-items: center;
    justify-content: center;
    margin: auto;
    margin-bottom: -40px;
    margin-left: 200px;
    margin-right: auto;
}

.parking-ticket {
    width: 95%;
    padding: 0px;
    border: 0px solid #535353;
    margin-left: auto;
    margin-right: auto;
    align-items: center;
    justify-content: center;
    border-radius: 20px;
    box-shadow: 2px 2px 8px 3px rgba(0, 0, 0, 0.2);
}

h1 {
    color: #323232;
    text-shadow: 4px 4px 10px rgba(0, 0, 0, 0.1);
    text-align: center;
}

.parking-ticket-header {
    text-align: center;
    margin-bottom: 0px;
    margin-top: -20px;
}

.ticket-details {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 10px;
}

.ticket-row {
    flex-direction: row;
    align-items: flex-start;
    flex-wrap: wrap;
    display: flex;
    gap: 10px;
}

.ticket-item {
    flex-grow: 2;
    display: flex;
    flex-direction: column;
    align-items: left;
    font-weight: bold;
    justify-content: center;
    border: 1px solid #ccc;
    border-radius: 15px;
    padding: 10px;
    box-shadow: 4px 4px 6px rgba(0, 0, 0, 0.04);
}

.title {
    font-size: 16px;
    padding-bottom: 2px;
}

.result {
    font-weight: 400;
}

.ticket-item p {
    margin: 0px;
}

.button-wrapper {
    position: fixed;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: calc(100vw - 240px);
    margin-bottom: 10px;
}

.button-text-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.button-icon {
    margin: 0px;
    width: 30px;
    height: auto;
    margin-right: 10px;
}

.button {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    color: #323232;
    background-color: #ffe374;
    font-size: 14;
    cursor: pointer;
    margin: 10px;
    z-index: 100;
    box-shadow: 4px 4px 6px rgba(0, 0, 0, 0.3);
    transition: 0.2s;
    padding: 10px;
    max-width: 250px;
    width: 215px;

}

.button.complete:hover {
    background-color: #323232;
    color: #ffe374;
}

.button.delete:hover {
    background-color: #323232;
    color: #ffe374;
}

.button.print:hover {
    background-color: #323232;
    color: #ffe374;
}

.loading-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 200px;
}

.loading-spinner {
    width: 40px;
    height: 40px;
    border: 4px solid #f3f3f3;
    border-top: 4px solid #ffe374;
    border-radius: 50%;
    animation: spin 1s linear infinite;
}

.input {
    display: inline-block;
    padding: 5px;
    font-size: 14px;
    color: black;
    width: 50px;
    font-weight: 500;
    border: 1px solid black;
    border-radius: 5px;
    transition: border-color 0.2s ease;
    background-color: white;
    color: #323232;
}

.input::placeholder {
    color: rgba(170, 170, 170, 0.8);
}

.input:focus::placeholder {
    color: transparent;
}

.input:focus {
    outline: none;
    border-color: #ffe374;
}

/* Styling for Ticket when loaded into a card */
.ticket-in-card .parking-ticket {
    width: 95%;
    padding: 10px;
    border: 0px solid #535353;
    border-radius: 20px;
    box-shadow: 2px 2px 8px 3px rgba(0, 0, 0, 0.2);
}

.ticket-in-card .parking-ticket-header {
    text-align: center;
    margin-bottom: 20px;
    margin-top: -20px;
}

.ticket-in-card .ticket-details {
    gap: 5px;
}

.ticket-in-card .ticket-row {
    flex-direction: row;
    align-items: flex-start;
    flex-wrap: wrap;
}

.ticket-in-card .ticket-item {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: left;
    justify-content: center;
    border: 1px solid #ccc;
    border-radius: 15px;
    padding: 10px;
    box-shadow: 4px 4px 6px rgba(0, 0, 0, 0.04);
}

.ticket-in-card .ticket-item p {
    margin: 0;
}

.ticket-in-card .button-wrapper {
    display: none;
}

.ticket-in-card .write-ticket-button {
    width: 100%;
    font-size: 16px;
    padding: 8px;
}

.ticket-in-card p {
    font-size: 14px;
}

.delete-overlay {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1000000;
}

.delete-overlay-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: white;
    padding: 20px;
    border-radius: 20px;
    box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.2);
}

/* Hide elements when printing */
@media print {

    /* Hide the header during printing */
    .header {
        display: none;
    }

    .App {
        background-color: transparent;
    }

    .body {
        background-color: transparent;
    }

    /* Hide the button during printing */
    .button-wrapper {
        display: none;
    }

    /* Hide unnecessary elements in the parking ticket */
    .parking-ticket-wrapper {
        justify-content: center;
        border: none;
        box-shadow: none;
    }

    .parking-ticket {
        margin-top: 75px;
        border: none;
        box-shadow: none;
    }

    .ticket-in-card .ticket-details {
        gap: 5px;
    }

    .ticket-in-card .ticket-row {
        flex-direction: row;
        align-items: flex-start;
        flex-wrap: wrap;
    }

    .ticket-in-card .ticket-item {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        align-items: left;
        justify-content: center;
        border: none;
        border-radius: 15px;
        padding: 10px;
        box-shadow: none
    }

    .ticket-in-card .ticket-item p {
        margin: 0;
    }

    .ticket-in-card .button-wrapper {
        display: none;
    }

    .ticket-in-card .write-ticket-button {
        width: 100%;
        font-size: 16px;
        padding: 8px;
    }

    .ticket-in-card p {
        font-size: 14px;
    }

    .ticket-item {
        flex-grow: 2;
        display: flex;
        flex-direction: column;
        align-items: left;
        border-radius: 15px;
        padding: 10px;
        box-shadow: none;
    }
}