.login-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: white;
    border-radius: 45px;
    box-shadow: 0 1px 28px 10px rgba(0, 0, 0, 0.1);
    width: 90%;
    max-width: 500px;
    height: 500px;
    margin: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 999;
}

.login-title {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 10px;
    color: #323232;
}

.login-input {
    width: 100%;
    max-width: 500px;
    height: 40px;
    border-radius: 8px;
    border: 1px solid #ccc;
    margin-bottom: 10px;
    padding-left: 10px;
    font-size: 20px;
    color: #323232;
	background-color: white;
}

.login-input:hover {
	border: 1px solid #7a7a7a;
}

.login-input:focus {
	outline: none;
	border: 1px solid #FFE374;
}

.login-paragraph {
    display: flex;
    justify-content: center;
    margin-bottom: 0px;
    margin-top: 0px;
    font-size: 20px;
    color: #323232;
    width: 95%;
    text-align: center;
}

.login-button {
    display: block;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    width: 175px;
    height: 50px;
    border-radius: 20px;
    background-color: #FFE374;
    font-size: 32px;
    font-weight: bold;
    color: white;
    cursor: pointer;
    border: transparent;
    box-shadow: 0px 0px 18px 4px rgba(0, 0, 0, 0.12);
    text-shadow: 0px 0px 18px rgba(0, 0, 0, 0.12);
    transition: 0.25s;
}

.login-button:hover {
    background-color: white;
    color: #FFE374;
    text-shadow: 0px 0px 18px rgba(0, 0, 0, 0.12);
    transition: 0.25s;
}

.login-button:active {
    box-shadow: inset 0px 0px 18px 4px rgba(0, 0, 0, 0.12);
}

.login-error-message {
    color: red;
    font-size: 16px;
    margin-top: 5px;
    margin-bottom: -15px;
}

.login-bottom-links {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    font-size: 16px;
    color: #323232;
}

.background-square {
    position: absolute;
    background-color: #D9D9D9;
    border-radius: 45px;
    opacity: 0.2;
    box-shadow: 0 0px 36px 18px rgba(0, 0, 0, 1.45);
    z-index: 0;
    transition: all 1.5s ease-in-out;
}
.coverer {
    position: absolute;
    width: 100%;
    height: 2000%;
    background: linear-gradient(to top, #FFFFFF, #00000000);
    top: newY;
    left: 0;
    z-index: 10000;
    transition: all 1s ease-in-out;
}
