body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
        "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
        monospace;
}

.typewriter {
    overflow: hidden;
    /* Hides the overflow to create the typing effect */
    white-space: nowrap;
    /* Keeps text on a single line */
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    /* Monospace font for consistent character width */
    display: inline-block;
    /* Inline block to respect width settings */
}

::-webkit-datetime-edit-ampm-field:focus,
::-webkit-datetime-edit-day-field:focus,
::-webkit-datetime-edit-hour-field:focus,
::-webkit-datetime-edit-millisecond-field:focus,
::-webkit-datetime-edit-minute-field:focus,
::-webkit-datetime-edit-month-field:focus,
::-webkit-datetime-edit-second-field:focus,
::-webkit-datetime-edit-week-field:focus,
::-webkit-datetime-edit-year-field:focus,
::-webkit-datetime-edit-text:focus {
    background-color: #D9D9D9;
    color: black;
    outline: none;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300..900;1,300..900&display=swap');

@layer utilities {

    /* Hide scrollbar for Chrome, Safari and Opera */
    .no-scrollbar::-webkit-scrollbar {
        display: none;
    }

    /* Hide x-scrollbar for Chrome, Safari and Opera */
    .no-scrollbar-x::-webkit-scrollbar-x {
        display: none;
    }

    /* Hide scrollbar for IE, Edge and Firefox */
    .no-scrollbar {
        -ms-overflow-style: none;
        /* IE and Edge */
        scrollbar-width: none;
        /* Firefox */
    }

    .frc {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }

    .fcc {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .gray-gradient {
        background: linear-gradient(to right, #202225, #36393F);
    }

    .light-gray-gradient {
        background: linear-gradient(to right, #F2F3F5, #EBEDEF);
    }

    .yellow-gradient {
        background: linear-gradient(to right, #FFE374, #FFF59D);
    }

    .rubik-light {
        @apply font-rubik font-light;
    }

    .rubik-normal {
        @apply font-rubik font-normal;
    }

    .rubik-medium {
        @apply font-rubik font-medium;
    }

    .rubik-bold {
        @apply font-rubik font-bold;
    }

    .rubik-extrabold {
        @apply font-rubik font-extrabold;
    }
}