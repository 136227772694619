.single-setting-container {
    display: grid;
    grid-template-columns: 1fr 3fr 1fr;
    padding: 10px;
    margin: 10px;
    margin-bottom: 20px;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    box-shadow: 0 1px 8px rgba(0, 0, 0, 0.5);
    background-color: #FFFFFF;
}

.single-setting-value {
    margin-right: 10px;
    font-size: 14px;
    font-weight: medium;
    color: #323232;
    margin-top: 30px;
}

.single-setting-input {
    width: 100%;
    height: 25px;
    border-radius: 3px;
    padding: 5px;
    border: 2px solid #B3B3B3;
    border-radius: 10px;
    font-size: 14px;
    padding-left: 10px;
    color: #323232;
    font-weight: medium;
}

.single-setting-input:hover {
    border: 2px solid #7a7a7a;
}

.single-setting-input:focus {
    outline: none;
    border: 2px solid #FFE374;
}

.single-setting-save-button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100px;
    height: 35px;
    justify-content: center;
    border: none;
    border-radius: 8px;
    background-color: #FFE374;
    font-size: 18px;
    font-weight: bold;
    color: #323232;
    cursor: pointer;
    margin-right: 20px;
    margin-left: auto;
    box-shadow: 0 0px 20px 2px rgba(0, 0, 0, 0.1);
}

.single-setting-save-button:hover {
    box-shadow: 0 0px 20px 2px rgba(0, 0, 0, 0.2);
}

.autobutton-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 77px;
    height: 30px;
    border-radius: 7px;
    box-shadow: 0px .88px 4.38px 0.88px rgba(0, 0, 0, .25);
    transition: 0.1s;
}

.autobutton-switch {
    display: flex;
    width: 30px;
    height: 23px;
    border-radius: 5.25px;
    box-shadow: 0px .88px 4.38px 0.88px rgba(0, 0, 0, .25);
    cursor: pointer;
    transition: 0.1s;
}

.autobutton-switch:hover {
    transform: translate(-6px);
    transition: 0.1s;
}

/* LOTS */
.lot-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    border-collapse: collapse;
    border-radius: 12px;
}

.lot-header-container {
    display: flex;
    flex-direction: row;
    background-color: #323232;
    color: #FFE374;
    border-radius: 12px;
    gap: 65px;
}

.lot-header {
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    height: 40px;
}

.table-content {
    margin-top: 10px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
}

.checkbox-container {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8.5px;
    height: 30px;
    width: 30px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
}

.select-check {
    width: 15px;
    height: 15px;
    cursor: pointer;
    accent-color: #FFE374;
}

.status-icon {
    position: flex;
    margin-top: 4px;
    width: 15px;
    height: 15px;
}

.table-row {
    display: flex;
    flex-direction: row;
    gap: 5px;
    margin-bottom: 10px;
}

.table-cell {
    display: flex;
    flex-direction: row;
    text-align: left;
    color: #323232;
    height: 30px;
}


.dropdown {
    display: flex;
    border-radius: 8.5px;
    height: 32px;
    font-size: 14px;
    font-weight: bold;
    text-align: left;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    background-color: #FFFFFF;
    cursor: pointer;
    appearance: none;
    padding-left: 5px;
    margin-top: 0px;
    margin-bottom: 0px;
    margin-right: 0px;
    margin-left: 0px;
}

.help-form-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 1px 28px 10px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 500px;
    margin: 0 auto;
}

.help-form-title {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 4px;
    color: #323232;
}

.help-form-input {
    width: 100%;
    max-width: 500px;
    height: 30px;
    border-radius: 5px;
    border: 2px solid #ccc;
    margin-bottom: 10px;
    font-size: 16px;
}

.help-form-input:hover {
    border: 2px solid #7a7a7a;
}

.help-form-input:focus {
    outline: none;
    border: 2px solid #FFE374;
}

.help-form-email {
    width: 240px;
    height: 30px;
    border-radius: 5px;
    border: 2px solid #ccc;
    margin-bottom: 10px;
    margin-right: 10px;
    font-size: 16px;
}

.help-form-email:hover {
    border: 2px solid #7a7a7a;
}

.help-form-email:focus {
    outline: none;
    border: 2px solid #FFE374;
}

.help-form-phone {
    margin-left: 5px;
    width: 240px;
    height: 30px;
    border-radius: 5px;
    border: 2px solid #ccc;
    margin-bottom: 10px;
    font-size: 16px;
}

.help-form-phone:hover {
    border: 2px solid #7a7a7a;
}

.help-form-phone:focus {
    outline: none;
    border: 2px solid #FFE374;
}

.help-form-description {
    width: 500px;
    max-width: 500px;
    min-width: 500px;
    height: 150px;
    min-height: 150px;
    border-radius: 5px;
    border: 2px solid #ccc;
    margin-bottom: 10px;
    font-size: 16px;
}

.help-form-description:hover {
    border: 2px solid #7a7a7a;
}

.help-form-description:focus {
    outline: none;
    border: 2px solid #FFE374;
}

.help-form-submit-button {
    width: 100px;
    height: 35px;
    border-radius: 12px;
    background-color: #323232;
    font-size: 20px;
    font-weight: bold;
    color: white;
    cursor: pointer;
    border: transparent;
    box-shadow: 0px 0px 18px 4px rgba(0, 0, 0, 0.12);
    transition: 0.2s;
}

.help-form-submit-button:hover {
    background-color: #FFE374;
    color: #323232;
    transition: 0.2s;
}