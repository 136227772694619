.save-button {
    display: block;
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 500px;
    margin-top: 300px;
    width: 150px;
    height: 35px;
    border-radius: 12px;
    background-color: red;
    font-size: 20px;
    font-weight: bold;
    color: #323232;
    cursor: pointer;
    border: transparent;
    box-shadow: 0px 0px 18px 4px rgba(0, 0, 0, 0.12);
}

.back-button-container {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    border: none;
    background: none;
    cursor: pointer;
}

.back-button-container:hover {
    border-radius: 200px;
}

.back-button-text {
    font-size: 18px;
    font-weight: bold;
    margin-top: 0px;
}

.history-container {
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 30px;
    width: 95%;
    border-radius: 48px;
    padding: 10px;
    box-shadow: 0px 0px 62px 1.24px rgba(0, 0, 0, .25);
    display: table;
    transition: opacity 0.5s ease-in-out;
}

.history-table {
    width: 100%;
    margin: 0 auto;
}

.no-plate-text {
	font-size: 20px;
	font-weight: bold;
	color: #5a5a5a;
	margin-top: 40px;
	width: 100%;
	text-align: center;
}

.barchart-container {
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: center;
    width: 150px;
    height: 25px;
    background-color: #FFFFFF;
    border-radius: 3.5px;
    margin-top: 10px;
    margin-bottom: 10px;
    box-shadow: 0px .88px 4.38px 0.88px rgba(0, 0, 0, .25);
}

.barchart-fill {
    height: 100%;
    border-radius: 3.5px;
    box-shadow: 1.75px 0px 2.63px 0px rgba(0, 0, 0, .25);
}

.barchart-percentage {
    position: absolute;
    font-weight: bold;
    color: #323232;
}

.barchart-overlay-container {
    position: absolute;
    width: auto;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
}

.barchart-overlay {
    background: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, .1);
    position: absolute;
    top: 0px;
    left: -100px;
    transform: translate(-50%, -50%);
    z-index: 9999;
    width: 180px;
    height: auto;
    font-size: 16px;
}